*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  font-family: QS, system-ui, sans-serif;
  font-weight: 350;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-full {
  bottom: 100%;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-none {
  max-width: none;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[1px\] {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-10 {
  --tw-translate-x: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scroll-ms-52 {
  scroll-margin-inline-start: 13rem;
}

.list-disc {
  list-style-type: disc;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-light {
  --tw-border-opacity: 1;
  border-color: rgb(217 255 204 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(5 33 30 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.fill-light {
  fill: #d9ffcc;
}

.p-3 {
  padding: .75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-\[70\%\] {
  font-size: 70%;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.leading-none {
  line-height: 1;
}

.text-light {
  --tw-text-opacity: 1;
  color: rgb(217 255 204 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:root {
  --spacing: clamp(3rem, 3.5vw, 6rem);
}

@font-face {
  font-family: MR;
  src: url("mr.f9af3f95.woff2") format("woff2-variations");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: QS;
  src: url("qs.96976af5.woff2") format("woff2-variations");
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

section a {
  text-decoration-line: underline;
}

.font-body {
  font-family: QS, system-ui, sans-serif;
  font-weight: 350;
}

body {
  font-size: clamp(1rem, 2.5vw, 1.7rem);
}

h1, h2 {
  font-family: MR, system-ui, sans-serif;
  font-weight: 700;
}

h4 {
  padding: var(--spacing) 0 calc(var(--spacing) / 2) 0;
  font-family: MR, system-ui, sans-serif;
  font-weight: 700;
}

.half-space {
  padding-top: calc(var(--spacing) / 2);
  padding-bottom: calc(var(--spacing) / 2);
}

.gap-spacing {
  gap: 0 calc(var(--spacing) * 2);
}

[x-cloak] {
  display: none !important;
}

#hiddenSections {
  left: -99999px;
}

.burger:before, .burger:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgb(217 255 204 / var(--tw-bg-opacity));
  width: 1rem;
  height: .125rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
}

.burger:before {
  transform: translateY(-4px);
}

.burger:after {
  transform: translateY(4px);
}

.active.burger:before {
  transform: translateY(0)rotate(45deg);
}

.active.burger:after {
  transform: translateY(0)rotate(-45deg);
}

.menu-line.active {
  animation: 1s ease-in-out animate-line;
  bottom: 0;
}

#nav-main li {
  padding-bottom: calc(var(--spacing) / 2);
  cursor: pointer;
  white-space: nowrap;
  font-family: MR, system-ui, sans-serif;
  font-size: 150%;
  font-weight: 700;
  line-height: 1.15;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@keyframes animate-line {
  0% {
    opacity: 0;
    bottom: 100%;
  }

  100% {
    opacity: 1;
    bottom: 0%;
  }
}

#page {
  padding-top: var(--spacing);
}

button {
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
  border-width: 1px;
  border-color: var(--color-light);
  border-radius: .25rem;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#choices button:hover {
  background-color: rgb(217 255 204 / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
}

#visibleSections section {
  transition: max-height .25s ease-in, opacity .35s ease-in .25s;
}

.prefade {
  opacity: 0;
  overflow: hidden;
  max-height: 0 !important;
}

section {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: calc(var(--spacing) / 2);
  padding-bottom: calc(var(--spacing) / 2);
  border-top-width: 1px;
}

@media (width >= 1024px) {
  section {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

section {
  border-color: var(--color-light);
}

section > div {
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
}

.simplebar-horizontal, .simplebar-placeholder, .simplebar-wrapper {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

@media (width >= 1024px) {
  .simplebar-horizontal, .simplebar-placeholder, .simplebar-wrapper {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
}

@media (width >= 1280px) {
  .simplebar-horizontal, .simplebar-placeholder, .simplebar-wrapper {
    margin-left: 12rem;
    margin-right: 12rem;
  }
}

.simplebar-scrollbar:before {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 255 204 / var(--tw-bg-opacity)) !important;
}

section > div > :last-child {
  padding-bottom: var(--spacing);
}

p {
  padding-bottom: calc(var(--spacing) / 2);
  max-width: 64rem;
}

h2 {
  padding-top: var(--spacing);
  padding-bottom: calc(var(--spacing) / 2);
  font-size: 300%;
  line-height: 1.1;
}

h3 {
  padding-top: var(--spacing);
  padding-bottom: calc(var(--spacing) / 2);
  font-size: 70%;
  font-weight: 350;
}

#choices {
  padding-top: calc(var(--spacing) / 2);
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 1.5rem;
  display: flex;
}

.wave, .wave:hover {
  transform-origin: 70% 70%;
  animation-name: wave-animation;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  display: inline-block;
}

.wave:hover {
  animation-delay: .5ss;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👋</text></svg>") 16 0, auto;
  animation-iteration-count: infinite;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(14deg);
  }

  50% {
    transform: rotate(-8deg);
  }

  75% {
    transform: rotate(14deg);
  }

  100% {
    transform: rotate(0);
  }
}

.hover\:border-light:hover {
  --tw-border-opacity: 1;
  border-color: rgb(217 255 204 / var(--tw-border-opacity));
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:grid {
    display: grid;
  }

  .md\:basis-1\/2 {
    flex-basis: 50%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }
}

@media (width >= 1280px) {
  .xl\:basis-2\/3 {
    flex-basis: 66.6667%;
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
}

/*# sourceMappingURL=index.0d366fbf.css.map */
