@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --spacing: clamp(3rem, 3.5vw, 6rem);
}

@font-face {
  font-family: "MR";
  src: url("../../fonts/mr.woff2") format("woff2-variations");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "QS";
  src: url("../../fonts/qs.woff2") format("woff2-variations");
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

section a {
  @apply underline;
}

.font-body {
  font-family: "QS", system-ui, sans-serif;
  font-weight: 350;
}

@layer base {
  html {
    font-family: "QS", system-ui, sans-serif;
    font-weight: 350;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
  }
}

body {
  font-size: clamp(1rem, 2.5vw, 1.7rem);
}

h1,
h2 {
  font-family: "MR", system-ui, sans-serif;
  font-weight: 700;
}

h4 {
  font-family: "MR", system-ui, sans-serif;
  font-weight: 700;
  padding: var(--spacing) 0 calc(var(--spacing) / 2) 0;
}

.half-space {
  padding-top: calc(var(--spacing) / 2);
  padding-bottom: calc(var(--spacing) / 2);
}

.gap-spacing {
  gap: 0 calc(var(--spacing) * 2);
}

[x-cloak] {
  display: none !important;
}

#hiddenSections {
  left: -99999px;
}

.burger:before,
.burger:after {
  content: "";
  @apply transition-all absolute h-0.5 w-4 bg-light;
}

.burger:before {
  transform: translateY(-4px);
}

.burger:after {
  transform: translateY(4px);
}

.active.burger:before {
  transform: translateY(0) rotate(45deg);
}

.active.burger:after {
  transform: translateY(0) rotate(-45deg);
}

.menu-line.active {
  animation: animate-line 1s ease-in-out;
  bottom: 0;
}

#nav-main li {
  @apply transition duration-300;
  font-size: 150%;
  padding-bottom: calc(var(--spacing) / 2);
  font-weight: 700;
  font-family: "MR", system-ui, sans-serif;
  cursor: pointer;
  line-height: 1.15;
  white-space: nowrap;
}

@keyframes animate-line {
  0% {
    bottom: 100%;
    opacity: 0;
  }

  100% {
    bottom: 0%;
    opacity: 1;
  }
}

#page {
  padding-top: var(--spacing);
}

button {
  @apply border rounded  transition duration-500;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
  border-color: var(--color-light);
}

#choices button:hover {
  @apply bg-light bg-opacity-10;
}

#visibleSections section {
  transition: max-height 0.25s ease-in 0s, opacity 0.35s ease-in 0.25s;
}

.prefade {
  @apply opacity-0 !max-h-0 overflow-hidden;
}

section {
  @apply border-t px-5 lg:px-10 half-space;
  border-color: var(--color-light);
}

section > div {
  @apply max-w-6xl mx-auto;
}

.simplebar-horizontal,
.simplebar-placeholder,
.simplebar-wrapper {
  @apply mx-6 lg:mx-11 xl:mx-48;
}

.simplebar-scrollbar::before {
  @apply !bg-light;
}

section > div > *:last-child {
  padding-bottom: var(--spacing);
}

p {
  padding-bottom: calc(var(--spacing) / 2);
  @apply max-w-5xl;
}

h2 {
  padding-top: var(--spacing);
  padding-bottom: calc(var(--spacing) / 2);
  font-size: 300%;
  line-height: 1.1;
}

h3 {
  font-weight: 350;
  padding-top: var(--spacing);
  padding-bottom: calc(var(--spacing) / 2);
  font-size: 70%;
}

#choices {
  @apply flex-wrap flex gap-6 content-start;
  padding-top: calc(var(--spacing) / 2);
}

.wave,
.wave:hover {
  animation-name: wave-animation;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  animation-delay: 1s;
  display: inline-block;
  animation-fill-mode: forwards;
}

.wave:hover {
  animation-delay: 0.5ss;
  animation-iteration-count: infinite;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👋</text></svg>")
      16 0,
    auto;
}

.wave {
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  50% {
    transform: rotate(-8deg);
  }
  75% {
    transform: rotate(14deg);
  }
  100% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
}
